import { Component, Output, EventEmitter } from '@angular/core';
import { MeineVars } from './meineVars';

@Component({
  selector: 'app-root', // das ist ein css(!)-selektor ... aha
/*   template: '<h1>Hallo Angular!</h1> <b>Ich bin in app.componet.ts und bin nur ein template-textstring, statt ein Link ("templateUrl") zur standardmäßigen app.component.html ' +
             'Ich bin auch nur zur sehen, wenn der punkt templateUrl auskommentiert ist</b> - Im Browser-Quelltext allerdings, bin ich sehr wohl zu sehen.' +
             'Könnte man im Notfall / bei Problemen als WARTUBGSMODUS-Ausgabe benutzen (und Rest deaktivieren - noch mehr könnte man das index.html)', */
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
title = 'test3';

testdaten = '123456789!!!';

Vars1 = new MeineVars('777777', '888888888');

heute = new Date();
sekunde = this.heute.getSeconds();

public testa = `blub`;
// this.testa = 'bla'

testb: string;
// testb = 'test-bbb';

public testc: string;

// typescript variablen
terra1: string;
terra2: Int16Array;
terra3: number;
alter: number = 33;
einArray: number[] = [1, 2, 3, 4, 5];
einObjekt: {schlüssel1: string, schlüssel2: number};
wort1 = 'bla';
wort2: any = 'blablub';
wort3: 'pulleralarm';
// template-string (typescript)
text1 = `Ich wollte schon immer eimal ${this.wort2} sagen`;
text2 = `Ich wollte schon immer eimal ${this.wort3} sagen`;
// -> geht nur, wenn in rückwärts geneigtes hochkomma (backtick)

isDisabled = false;
meinKlickListener(typ){
  console.log(`Button ${typ} wurde geklickt!`);
  // ausgabe in entwicklertools / Tab Console (Chrome)
}




// constructor() { } // üblicherweise: @Dekorator dekoriert Klasse in Klasse/Methode, die in nächster Zeile kommt [-> nochmal sehen, ob vorher bei "input" noch was dazu gesagt wurde]
}
