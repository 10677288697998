export class MeineVars {
  constructor(
    public testdaten2: string,
    public testdaten4: string
  ) {

    let testdaten3 = '33333333333';
    testdaten4 = '444444444444';
    let testdaten5 = '555555555555';
  }
}
