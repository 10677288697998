import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-routingtest2',
  templateUrl: './routingtest2.component.html',
  styleUrls: ['./routingtest2.component.css']
})
export class Routingtest2Component implements OnInit {

  uebergebeneId1: string;
  uebergebeneId2: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.uebergebeneId1 = this.route.snapshot.paramMap.get('id');

    this.route.paramMap.subscribe (paramMap => this.uebergebeneId2 = paramMap.get('id'));

  }

}
