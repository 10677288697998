<br /><br />--- ! anfang in app.component.html ! ---<br /><br />

<router-outlet><a routerLink = '/eins'>Routingtestseite 1 aufrufen</a></router-outlet>


<h1 align="center">blabla</h1>
<h2>superblabla</h2>
<p font size = "24">supersuperblabla</p>

<!-- <div class="ui active inverted dimmer">
    <div class="ui text loader large">Mensch, ärgere dich nicht!</div>
</div> -->

<i class="sign language icon"></i>
<br />
{{ text1 }}
<br />
{{ text2 }}
<br />
{{ 1+1 }}
<br />
{{ testdaten }}
<br />
<br />
{{ Vars1.testdaten2 }}
<br />
{{ Vars1.testdaten4 }}
<!-- {{ Vars1.testdaten5 }} -->
<br />
<!-- interpolation und strukturdirektive -->
<div *ngIf="sekunde % 2 == 0">Es ist eine gerade Sekunde: {{sekunde}}</div>
<div *ngIf="sekunde % 2 != 0">Es ist eine ungerade Sekunde: {{sekunde}}</div>
<br />
<!-- Property-Binding (satt isDisabled auch möglich: "funktion()"(die dann in component.ts ist) ) -->
<button [disabled]=isDisabled>Test Disabled (Property-Binding)</button>
<br />
<!-- Event-Binding -->
<button (click)="meinKlickListener('typ a')">Test Konsolenausgabe (Event-Binding)</button>
    <!-- eigenes event (hierüber war eins der standardevents: click) -->
<!-- <meinErzeugtesEreignis- -->
<br />
<!-- TwoWay-Binding -->
<input [(ngModel)]="text1" />
<!-- Damit das funktioniert in app.module.ts:
  import { FormsModule } from '@angular/forms';
  und FormsModule dort in den NgModule-imports-Block -->

<!-- weitere 03-2 -->
<br /><br />--- ! ende in app.component.html ! ---<br /><br />

<app-unterkomponente1></app-unterkomponente1>
