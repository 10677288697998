<br />
--- ! anfang in app.component.html ! ---
<br />

<!-- Property-Binding (satt isDisabled auch möglich: "funktion()"(die dann in component.ts ist) ) -->
<button [disabled]=isDisabled>Test Disabled (Property-Binding)</button>
<br />

<!-- Event-Binding -->
<button (click)="meinKlickListener('typ a')">Test Konsolenausgabe (Event-Binding)</button>
    <!-- eigenes event (hierüber war eins der standardevents: click) hierfür hab wie in beispiel jetzt unterkomponente - in oberkomponente später probieren ! -->
<!-- <meinErzeugtesEreignis- -->
<br />
<button (meinEreignis1)="meinKlickListener($event)">Test Eigenes Ereignis</button>







<br />
--- ! ende in app.component.html ! ---
<br />
