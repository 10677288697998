import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-unterkomponente1',
  templateUrl: './unterkomponente1.component.html',
  styleUrls: ['./unterkomponente1.component.css']
})
export class Unterkomponente1Component implements OnInit {

  wort1 = 'trallala';
  text1 = `Ich wollte schon immer eimal ${this.wort1} sagen`;

  isDisabled = true;
  teststring: string;
  //this.teststring = 'ich ein ein teststring';
  teststring2 = 'Ich weiß nicht, was soll es bedeuten';

  @Output() private meinEreignis1 = new EventEmitter<string>();
  // -> dekoriert den event-emitter (sonst wird es nicht weitergereicht)
  // von den in Auswahl angezeigten EventEmittern, denjenigen von angular@core ausgewählt
  // - dieser wird dann auch gleich automatisch mit, oben bei imports eingetragen / zusätzlich eingefügt
  // - muss beim kopieren dieser Zeile in Unterkomponente nachgeholt werden, ebenso hier für Output
  // im Buchprojekt haben wir als generischen Typ (in eckigen Klammern) Book eingetragen

  constructor() { }

  ngOnInit(): void {
    this.meinEreignis1.emit(this.teststring2);
    // auslösemethode löst ereignis aus, hier zum testen
  }


  meinKlickListener(typ){
    console.log(`Button ${typ} wurde geklickt!`);
    // ausgabe in entwicklertools / Tab Console (Chrome)
  }





}
