import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Routingtest1Component } from './routingtest1/routingtest1.component';
import { Routingtest2Component } from './routingtest2/routingtest2.component';



const routes: Routes = [
  { path: 'eins', component: Routingtest1Component },
  { path: 'zwei', component: Routingtest2Component },
  { path: 'zwei/:id', component: Routingtest2Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
