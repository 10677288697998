import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Unterkomponente1Component } from './unterkomponente1/unterkomponente1.component';
import { Routingtest1Component } from './routingtest1/routingtest1.component';
import { Routingtest2Component } from './routingtest2/routingtest2.component';

@NgModule({
  declarations: [
    AppComponent,
    Unterkomponente1Component,
    Routingtest1Component,
    Routingtest2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
